import styled from '@emotion/styled';
import { Tab, TabProps } from '@dc3/ui/tab-bar';
import { ThemeProps } from '@dc3/utils/theme';

type TabPropsExtended = ThemeProps &
  TabProps & {
    active: boolean;
  };

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }: TabPropsExtended) => ({
  opacity: 1,
  padding: '6px 26px',
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  overflow: 'visible',
}));
