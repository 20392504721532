import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { TabBar, TabProps } from '@dc3/ui/tab-bar';
import { StyledTab } from './TabNavigation.styles';
import { useRouter } from 'next/router';

export interface Tab extends TabProps {
  name: TabProps['label'];
  route: string;
  isNotViewable?: boolean;
}

interface TabNavigationProps {
  tabs: Tab[];
  withElevation?: boolean;
}

const getActiveTabIndexFromUrl = (tabs: Tab[], path: string) => {
  const tabIndex = tabs.findIndex((tab) => path.includes(tab.route));
  return tabIndex < 0 ? 0 : tabIndex;
};

export const TabNavigation = ({
  tabs,
  withElevation = true,
  ...rest
}: TabNavigationProps) => {
  const router = useRouter();
  const path = router.asPath;

  const filteredTabs = tabs.filter((tab) => !tab.isNotViewable);
  const [activeTab, setActiveTab] = useState(
    getActiveTabIndexFromUrl(filteredTabs, path),
  );

  useEffect(() => {
    setActiveTab(getActiveTabIndexFromUrl(filteredTabs, path));
  }, [path, filteredTabs]);

  const onTabChange = (_e: React.ChangeEvent<unknown>, tabIndex: number) =>
    setActiveTab(tabIndex);

  return (
    <TabBar
      handleChange={onTabChange}
      value={activeTab}
      withElevation={withElevation}
      {...rest}
    >
      {filteredTabs.map((tab, index) => (
        <Link href={tab.route} key={index} shallow passHref legacyBehavior>
          <StyledTab
            label={tab.name}
            active={activeTab === index}
            data-testid={`tab-${tab.name}`}
          />
        </Link>
      ))}
    </TabBar>
  );
};
