import { css } from '@emotion/react';
import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';

const weights = {
  thin: 300,
  regular: 400,
  semibold: 500,
  bold: 700,
  bolder: 900,
};

const textStyles = ({ weight, textTransform }: StyledTextProps) => css`
  font-weight: ${weight ? weights[weight] : weights.regular};
  text-transform: ${textTransform ? textTransform : 'none'};
`;

export const StyledText = styled(Typography, {
  // prevent DOM error unknown prop error
  shouldForwardProp: (prop) => prop !== 'textTransform',
})`
  ${textStyles}
`;

export interface StyledTextProps extends TypographyProps {
  weight?: 'thin' | 'regular' | 'semibold' | 'bold' | 'bolder';
  textTransform?: 'uppercase' | 'capitalize' | 'none';
  component?: React.ElementType;
}
