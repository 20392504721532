import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import MaterialListItemText from '@mui/material/ListItemText';

const listItemCSS = ({
  theme,
  borders,
}: ThemeProps & { borders: boolean }) => css`
  color: ${theme.palette.text.primary};
  height: 42px;
  border-bottom: 1px solid ${borders ? theme.palette.divider : 'transparent'};
  background-color: rgba(0, 0, 0, 0);

  &.Mui-selected {
    color: ${theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &:hover {
    color: ${theme.palette.primary.main};
    background-color: rgba(0, 0, 0, 0);
  }
`;

const innerWrapperCSS = ({ selected }: { selected: boolean }) => css`
  flex-direction: row;
  display: flex;
  flex: 1;
  ${selected
    ? `
padding: 8px 0 8px 5px;
background-color: rgba(3, 112, 131, 0.15);
border-radius: 4px;
`
    : ''}
  &:hover {
    .icon {
      color: #037083;
    }
  }
`;

export const InnerWrapper = styled.div`
  ${innerWrapperCSS}
`;

export const StyledMenuItem = styled(ListItemButton, {
  // prevents DOM error: Received true for non-boolean attribute 'borders'
  shouldForwardProp: (prop) => prop !== 'borders',
})`
  ${listItemCSS}
`;

const menuIconCSS = ({
  theme,
  selected,
}: ThemeProps & { selected: boolean; sidebarIsOpen?: boolean }) => css`
  transition: all 0.3s ease;
  color: ${selected
    ? theme.palette.primary.main
    : theme.palette.text.secondary};
  span {
    font-size: 20px;
  }
`;

export const StyledMenuIcon = styled(ListItemIcon)`
  ${menuIconCSS}
`;

export const StyledListTitle = styled(ListSubheader)`
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
  line-height: normal;
  margin-bottom: 8px;
`;

export const StyledListItemText = styled(MaterialListItemText)`
  white-space: nowrap;
  span {
    font-size: 14px;
  }
`;
