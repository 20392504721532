import React from 'react';
import MaterialTabs, {
  TabsProps as MaterialTabsProps,
} from '@mui/material/Tabs';
import MaterialTab, { TabProps } from '@mui/material/Tab';
import { AppBar } from '@mui/material';
import { Divider } from '@dc3/ui/divider';

export const Tabs = MaterialTabs;
export const Tab = MaterialTab;
export type { TabProps };

/* The Content that gets shown*/
interface TabContentProps {
  index: number;
  currentTab: number;
  height?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

/* The Actual Bar which holds the tabs */
interface TabBarProps extends MaterialTabsProps {
  handleChange?: (event: React.ChangeEvent<unknown>, newValue: number) => void;
}

export const tabA11yProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export const TabContent = ({
  children,
  index,
  currentTab,
  height = 'auto',
  style,
}: TabContentProps) => (
  <div
    style={{ height: height, ...style }}
    role="tabpanel"
    hidden={index !== currentTab}
    aria-labelledby={`tab-${index}`}
    id={`tabpanel-${index}`}
  >
    {index === currentTab && children}
  </div>
);

interface TabBarCustomProps extends TabBarProps {
  withElevation?: boolean;
}

export const TabBar = ({
  indicatorColor = 'primary',
  textColor = 'primary',
  withElevation = true,
  value,
  handleChange,
  children,
  ...rest
}: TabBarCustomProps) => (
  <AppBar
    position="static"
    color="inherit"
    elevation={withElevation ? 1 : 0}
    sx={{ boxShadow: 'none' }}
  >
    <MaterialTabs
      indicatorColor={indicatorColor}
      textColor={textColor}
      value={value}
      onChange={handleChange}
      {...rest}
    >
      {children}
    </MaterialTabs>
    <Divider />
  </AppBar>
);
