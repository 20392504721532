import React from 'react';
import { Box } from '@dc3/ui/box';
import { useTheme } from '@dc3/utils/theme';
import { BoxProps } from '@mui/material';

export const PageColumnsWrapper = ({ children, ...rest }: BoxProps) => (
  <Box display="flex" paddingTop={1} height="100%" {...rest}>
    {children}
  </Box>
);

export const PageLeftColumn = ({ children, ...rest }: BoxProps) => {
  const theme = useTheme();

  return (
    <Box
      flex="1"
      maxWidth="460px"
      paddingRight={3}
      borderRight={`1px solid ${theme.palette.grey[300]}`}
      {...rest}
    >
      {children}
    </Box>
  );
};

export const PageRightColumn = ({ children, ...rest }: BoxProps) => (
  <Box flex="2" height="100%" width="100%" paddingLeft={4} {...rest}>
    {children}
  </Box>
);
