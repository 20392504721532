import React, { useEffect, useState } from 'react';
import getConfig from 'next/config';
import MaterialDrawer from '@mui/material/Drawer';
import { Icon } from '@dc3/ui/icon';
import { Menu } from '@dc3/ui/menu';
import { ActionsMenu } from '../actions-menu/ActionsMenu';
import { useMainNavigation } from '../hooks/use-main-navigation';
import { DC3_SIDE_BAR_KEY, Pages } from '@dc3/utils/constants';
import {
  StyledSideBar,
  Version,
  CollapseDrawerButton,
  StyledActionsSection,
} from './Sidebar.styles';
import { useSharedState } from '@dc3/data-access/client-state';
import { isClientSide } from '@dc3/utils/helpers';
import { useWindowSize } from '@dc3/utils/hooks';
import { LogoWrapper } from '../top-bar/TopBar.styles';
import { Logo } from '@dc3/ui/logo';
import { useTheme, ViewModes } from '@dc3/utils/theme';
import { useUserData } from '@dc3/utils/auth';

const SMALL_SCREEN_THRESHOLD = 1600;
const localStorageValue = JSON.parse(
  (isClientSide() && localStorage.getItem(DC3_SIDE_BAR_KEY)) || 'true',
) as boolean;

export const SideBar = () => {
  const {
    publicRuntimeConfig: { TAG_NAME, MEDIA_SERVER },
  } = getConfig();
  const { state, actions } = useSharedState();
  const { width } = useWindowSize();
  const theme = useTheme();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Remove disabled pages and do not show Settings in the main menu.
  const menuItems = useMainNavigation().filter(
    (item) => item.enabled && item.id !== Pages.Settings,
  );

  const { viewMode } = useUserData();
  const isDarkMode = viewMode === ViewModes.DC3Dark;

  useEffect(() => {
    setIsSmallScreen((width || window?.innerWidth) < SMALL_SCREEN_THRESHOLD);
  }, [width]);

  useEffect(() => {
    if (isClientSide()) {
      const isSidebarOpen = !isSmallScreen && localStorageValue;
      actions.setIsSidebarOpen(isSidebarOpen);
    }
  }, [actions, isSmallScreen]);

  const onCollapseButtonClick = () => {
    const newValue = !state.isSidebarOpen;
    actions.setIsSidebarOpen(newValue);

    // Make persistent
    localStorage.setItem(DC3_SIDE_BAR_KEY, newValue.toString());
  };

  const icon = isDarkMode ? `dark_no-subtitle.svg` : `light_no-subtitle.svg`;
  const logoUrl = () =>
    state.isSidebarOpen
      ? `${MEDIA_SERVER}/${icon}`
      : `${MEDIA_SERVER}/logo-only.svg`;

  return (
    <StyledSideBar
      sidebarIsOpen={state.isSidebarOpen}
      onMouseOver={() => isSmallScreen && actions.setIsSidebarOpen(true)}
      onMouseOut={() => isSmallScreen && actions.setIsSidebarOpen(false)}
    >
      {!isSmallScreen && (
        <CollapseDrawerButton onClick={onCollapseButtonClick}>
          <Icon customColor={theme.palette.text.secondary}>
            {state.isSidebarOpen ? 'chevron_left' : 'chevron_right'}
          </Icon>
        </CollapseDrawerButton>
      )}

      <MaterialDrawer variant="permanent">
        <LogoWrapper sidebarIsOpen={state.isSidebarOpen}>
          <a href="/">
            <Logo url={logoUrl() as string} height="30px" />
          </a>
        </LogoWrapper>

        <Menu items={menuItems} isExpanded={state.isSidebarOpen} />

        <StyledActionsSection sidebarIsOpen={state.isSidebarOpen}>
          <ActionsMenu />
        </StyledActionsSection>
        {state.isSidebarOpen && <Version>{TAG_NAME}</Version>}
      </MaterialDrawer>
    </StyledSideBar>
  );
};
