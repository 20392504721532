import React from 'react';
import { useConfiguration } from '@dc3/utils/providers';
import { Menu, MenuItemInterface } from '@dc3/ui/menu';
import { HotKeys } from '@dc3/utils/constants';
import { useSharedState } from '@dc3/data-access/client-state';
import { useI18n } from '@dc3/utils/hooks';

export const ActionsMenu = () => {
  const translate = useI18n('global.menuActions');
  const { features } = useConfiguration();
  const { state, actions } = useSharedState();

  const menuItems: MenuItemInterface[] = [
    {
      title: translate('impersonate'),
      icon: 'supervised_user_circle',
      onClick: () => actions.setShowImpersonationDialog(true),
      enabled: features.impersonation,
      description: HotKeys.Impersonate.toUpperCase(),
    },
    {
      title: translate('feedback'),
      icon: 'feedback',
      onClick: () => actions.setShowFeedbackDialog(true),
      enabled: true,
    },
    {
      title: translate('releaseNotes'),
      icon: 'assignment',
      onClick: () => actions.setShowReleaseNotesDialog(true),
      enabled: true,
    },
  ];

  return (
    <Menu
      items={menuItems}
      isExpanded={state.isSidebarOpen}
      title={translate('title')}
    />
  );
};
