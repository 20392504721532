import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Paper } from '@dc3/ui/paper';

export const StyledCard = styled(Paper)`
  flex-grow: 1; /* To achieve 100% height in the proper way. The parent (Layout.tsx) wraps the Card in a Flexbox columns direction. */
  position: relative;
  border-radius: 0;
`;

export const StyledCardContent = styled.div`
  ${({ theme }: ThemeProps) => css`
    position: absolute;
    left: ${theme.spacing(3)};
    top: ${theme.spacing(3)};
    bottom: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
    word-wrap: break-word;
  `}
`;
