import React, { useEffect, useState } from 'react';
import { ConditionalWrap } from '@dc3/utils/helpers';
import MaterialList from '@mui/material/List';
import { Icon } from '@dc3/ui/icon';
import { Tooltip } from '@dc3/ui/tooltip';
import { Pages, SubPages } from '@dc3/utils/constants';
import { useSharedState } from '@dc3/data-access/client-state';
import {
  InnerWrapper,
  StyledListItemText,
  StyledListTitle,
  StyledMenuIcon,
  StyledMenuItem,
} from './Menu.styles';
import { Box, Collapse } from '@mui/material';
import { useRouter } from 'next/router';

export interface MenuItemInterface {
  id?: Pages | SubPages;
  title: string;
  icon?: string;
  enabled: boolean;
  description?: string;
  onClick?: () => void;
  route?: string;
  children?: MenuItemInterface[];
}

interface MenuProps {
  items: MenuItemInterface[];
  title?: string;
  isExpanded?: boolean;
  borders?: boolean;
}

const TOOLTIP_DELAY = 800;

export const Menu = ({
  isExpanded,
  items,
  title: menuTitle,
  borders = false,
}: MenuProps) => {
  // Shared state
  const { state } = useSharedState();
  const { pathname, basePath } = useRouter();
  const route = pathname !== '/' ? `/${pathname.split('/')[1]}` : '';
  const [open, setOpen] = useState([]);

  useEffect(() => {
    if (pathname) {
      const result = basePath.substring(1);
      expandMenu(result);
    }
  }, [pathname, basePath]);

  const isSelected = (path: string) => (basePath + route)?.includes(path);

  const onMenuItemClick = (item: MenuItemInterface) => {
    if (item?.children && state?.isSidebarOpen) {
      expandMenu(item?.id);
    } else {
      if (item?.route) {
        window.location.href = item?.route;
      } else {
        item?.onClick();
      }
    }
  };

  const expandMenu = (id) => {
    if (open.includes(id)) {
      const closeMenu = open.filter((e) => e !== id);
      setOpen(closeMenu);
    } else {
      setOpen([...open, id]);
    }
  };

  const showTitle = Boolean(menuTitle) && isExpanded;

  const renderMenu = (item: MenuItemInterface, displayIcon = true) => (
    <Box key={'menu-item-' + item?.id} display={!item?.enabled && 'none'}>
      <StyledMenuItem
        onClick={() => onMenuItemClick(item)}
        selected={isSelected(item?.route)}
        borders={isExpanded && borders}
      >
        <InnerWrapper selected={displayIcon && isSelected(item?.route)}>
          {displayIcon && (
            <StyledMenuIcon selected={displayIcon && isSelected(item?.route)}>
              <Icon className="icon">{item?.icon}</Icon>
            </StyledMenuIcon>
          )}

          {state?.isSidebarOpen && (
            <>
              <StyledListItemText primary={item?.title} />
              {item?.children && (
                <StyledMenuIcon selected={isSelected(item?.route)}>
                  {open.includes(item?.id) ? (
                    <Icon>expand_less</Icon>
                  ) : (
                    <Icon>expand_more</Icon>
                  )}
                </StyledMenuIcon>
              )}
            </>
          )}
        </InnerWrapper>
      </StyledMenuItem>
      {item?.children && isExpanded && (
        <Collapse
          in={open.includes(item?.id)}
          timeout="auto"
          unmountOnExit
          sx={{ marginLeft: '32px', paddingRight: '5px' }}
        >
          {item?.children.map((child) => renderMenu(child, false))}
        </Collapse>
      )}
    </Box>
  );

  return (
    <MaterialList
      disablePadding
      sx={{ m: 0 }}
      subheader={
        showTitle ? (
          <StyledListTitle disableSticky>{menuTitle}</StyledListTitle>
        ) : null
      }
    >
      {items.map((item, index) => (
        <ConditionalWrap
          key={index}
          condition={Boolean(item?.description)}
          wrap={(children) => (
            <Tooltip
              variant="primary"
              placement="right"
              title={
                isExpanded
                  ? item?.description
                  : `${item?.title} - ${item?.description}`
              }
              enterDelay={TOOLTIP_DELAY}
            >
              {children}
            </Tooltip>
          )}
        >
          {renderMenu(item)}
        </ConditionalWrap>
      ))}
    </MaterialList>
  );
};
