import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StyledMain = styled('main')`
  ${({ theme }: ThemeProps) => css`
    flex-grow: 1;
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    display: flex;
    overflow: hidden;
    flex-direction: column;
  `}
`;
