import React from 'react';
import getConfig from 'next/config';
import MaterialMenu, { MenuProps } from '@mui/material/Menu';
import MaterialMenuItem from '@mui/material/MenuItem';
import MaterialMenuItemIcon from '@mui/material/ListItemIcon';
import { Icon } from '@dc3/ui/icon';
import { useUserData } from '@dc3/utils/auth';
import { UserMenuTitle, StyledMaterialMenuItem } from './UserMenu.styles';
import { useI18n } from '@dc3/utils/hooks';

interface UserMenuProps extends Pick<MenuProps, 'onClose' | 'anchorEl'> {
  userMenuIsOpen: boolean;
}

const {
  publicRuntimeConfig: { SSO_LOGOUT_URL },
} = getConfig();

export const UserMenu = ({
  userMenuIsOpen,
  onClose,
  anchorEl,
}: UserMenuProps) => {
  const translate = useI18n();
  const { userData } = useUserData();

  return (
    <MaterialMenu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={userMenuIsOpen}
      onClose={onClose}
    >
      <MaterialMenuItem key="usermenu-title" divider disabled>
        <UserMenuTitle>{userData?.username || 'Account'}</UserMenuTitle>
      </MaterialMenuItem>

      <StyledMaterialMenuItem
        key="usermenu-logout-item"
        onClick={() => (window.location.href = SSO_LOGOUT_URL)}
      >
        <MaterialMenuItemIcon>
          <Icon>logout</Icon>
        </MaterialMenuItemIcon>
        {translate('global.header.logout')}
      </StyledMaterialMenuItem>
    </MaterialMenu>
  );
};
