import React from 'react';
import { StyledCard, StyledCardContent } from './PageCard.styles';
import { CardProps } from '@mui/material';

export const PageCard = ({
  children,
  ...rest
}: CardProps & {
  children?: React.ReactNode;
}) => (
  <StyledCard {...rest}>
    <StyledCardContent>{children}</StyledCardContent>
  </StyledCard>
);
