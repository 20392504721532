import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/compat/router';
import MaterialToolbar from '@mui/material/Toolbar';
import { UserMenu } from './UserMenu';
import {
  DC3_COUNTRY_CODE,
  DC3_LOCALE,
  DC3_VIEW_MODE,
} from '@dc3/utils/constants';
import { Country } from '@dc3/utils/tenant-config';
import { useI18n } from '@dc3/utils/hooks';
import { Icon } from '@dc3/ui/icon';
import { Tooltip } from '@dc3/ui/tooltip';
import { CountrySelector } from '@dc3/ui/form-fields';
import { useUserData } from '@dc3/utils/auth';
import { useTheme, ViewModes } from '@dc3/utils/theme';
import { StyledTopBar, StyledIconButton } from './TopBar.styles';
import { useSharedState } from '@dc3/data-access/client-state';

interface TopBarProps {
  headerTitle: string;
  showCountrySelector?: boolean;
}

export const TopBar = ({ headerTitle, showCountrySelector }: TopBarProps) => {
  const translate = useI18n();
  const router = useRouter();
  const { state } = useSharedState();

  const {
    viewMode,
    setViewMode,
    country: { code: initialCountryCode } = {},
  } = useUserData();
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const theme = useTheme();
  const isDarkMode = viewMode === ViewModes.DC3Dark;

  // User menu handling
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userMenuIsOpen = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const setLocaleCookies = (country: Country) => {
    setCountryCode(country.code);
    Cookies.set(DC3_COUNTRY_CODE, country.code);
    Cookies.set(DC3_LOCALE, country.locale);
  };

  const onCountryChange = (country: Country) => {
    setLocaleCookies(country);
    window.location.href = router.basePath;
  };

  const handleViewModeChange = () => {
    const newViewMode =
      viewMode === ViewModes.DC3Dark ? ViewModes.DC3Light : ViewModes.DC3Dark;
    setViewMode(newViewMode);
    Cookies.set(DC3_VIEW_MODE, newViewMode, { expires: 3650 });
  };

  return (
    <StyledTopBar
      color="primary"
      position="fixed"
      elevation={1}
      sidebarIsOpen={state.isSidebarOpen}
    >
      <MaterialToolbar disableGutters sx={{ justifyContent: 'end' }}>
        {showCountrySelector && (
          <CountrySelector
            value={countryCode as Country['code']}
            onCountryChange={onCountryChange}
            mode="iconOnly"
          />
        )}

        <Tooltip
          variant="primary"
          title={translate(`global.${isDarkMode ? 'turnLight' : 'turnDark'}`)}
        >
          <StyledIconButton onClick={handleViewModeChange}>
            <Icon customColor={theme.palette.text.secondary}>
              {isDarkMode ? 'wb_sunny' : 'brightness_3'}
            </Icon>
          </StyledIconButton>
        </Tooltip>

        <Tooltip
          variant="primary"
          title={translate('global.header.userSettings')}
        >
          <StyledIconButton
            onClick={() => (window.location.href = '/settings')}
          >
            <Icon
              customColor={theme.palette.text.secondary}
              sx={{ fontSize: 1 }}
            >
              settings
            </Icon>
          </StyledIconButton>
        </Tooltip>

        <Tooltip variant="primary" title={translate('global.account')}>
          <StyledIconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <Icon customColor={theme.palette.text.secondary}>
              account_circle
            </Icon>
          </StyledIconButton>
        </Tooltip>

        <UserMenu
          userMenuIsOpen={userMenuIsOpen}
          onClose={handleClose}
          anchorEl={anchorEl}
        />
      </MaterialToolbar>
    </StyledTopBar>
  );
};
