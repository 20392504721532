import { MenuItemInterface } from '@dc3/ui/menu';
import { accessControlConfiguration, useUserData } from '@dc3/utils/auth';
import { Pages, SubPages } from '@dc3/utils/constants';
import { useI18n } from '@dc3/utils/hooks';
import { useAccessControl, useConfiguration } from '@dc3/utils/providers';
import getConfig from 'next/config';
import { useMemo } from 'react';

export const useMainNavigation = () => {
  const translate = useI18n('global.menu');
  const { canViewPage, features } = useConfiguration();
  const { country } = useUserData();
  const {
    publicRuntimeConfig: { TENANT },
  } = getConfig();

  const isQuotationsEnabled = useMemo(
    () => canViewPage('quotation', country),
    [country, canViewPage],
  );
  const isDashboardEnabled = canViewPage('dashboard');
  const isCampaignEnabled = canViewPage('campaign');
  const isOrganizationEnabled = canViewPage('organization');
  const isCatalogEnabled = canViewPage('catalog');
  const isEdithEnabled = canViewPage('edith');
  const isSearchEnabled = canViewPage('search');
  const isAlphaEnabled = canViewPage('alpha');
  const isDeliveryEnabled = canViewPage('delivery');
  const isHyperloopEnabled = canViewPage('hyperloop');
  const isRoleEnabled = canViewPage('role');
  const isExperimentationEnabled = canViewPage('experimentation');
  const isFeatureTogglesEnabled = canViewPage('feature-toggles');

  const { pageAccessControl } = accessControlConfiguration[TENANT] || {};

  const canAccessDashboard = useAccessControl(
    pageAccessControl?.['/dashboard'],
  );
  const canAccessCampaign = useAccessControl(pageAccessControl?.['/campaign']);
  const canAccessHyperloop = useAccessControl(
    pageAccessControl?.['/hyperloop'],
  );
  const canAccessQuotation = useAccessControl(
    pageAccessControl?.['/quotation'],
  );
  const canAccessOrganization = useAccessControl(
    pageAccessControl?.['/organization'],
  );
  const canAccessExperimentation = useAccessControl(
    pageAccessControl?.['/experimentation'],
  );
  const canAccessSearch = useAccessControl(pageAccessControl?.['/search']);

  const menuItems: MenuItemInterface[] = [
    {
      id: Pages.Dashboard,
      title: translate('dashboardTitle'),
      description: translate('dashboardDescription'),
      route: `/${Pages.Dashboard}`,
      icon: 'home',
      enabled: isDashboardEnabled && canAccessDashboard,
    },
    {
      id: Pages.Campaign,
      title: translate('campaignTitle'),
      description: translate('campaignDescription'),
      route: `/${Pages.Campaign}`,
      icon: 'campaign',
      enabled: isCampaignEnabled && canAccessCampaign,
    },
    {
      id: Pages.Quotation,
      title: translate('quotationTitle'),
      description: translate('quotationDescription'),
      route: `/${Pages.Quotation}`,
      icon: 'shopping_cart',
      enabled: isQuotationsEnabled && canAccessQuotation,
      children: [
        {
          id: SubPages.Quotations,
          title: translate('quotationOverviewTitle'),
          description: translate('quotationDescription'),
          route: `/${Pages.Quotation}/overview`,
          enabled: isQuotationsEnabled && canAccessQuotation,
        },
        {
          id: SubPages.Holds,
          title: translate('holdsTitle'),
          description: translate('quotationDescription'),
          route: `/${Pages.Quotation}/${SubPages.Holds}`,
          enabled: isQuotationsEnabled && canAccessQuotation,
        },
        {
          id: SubPages.Proposals,
          title: translate('proposalsTitle'),
          description: translate('quotationDescription'),
          route: `/${Pages.Quotation}/${SubPages.Proposals}`,
          icon: 'shopping_cart',
          enabled: isQuotationsEnabled && canAccessQuotation,
        },
        {
          id: SubPages.Pending,
          title: translate('pendingTitle'),
          description: translate('quotationDescription'),
          route: `/${Pages.Quotation}/${SubPages.Pending}`,
          icon: 'shopping_cart',
          enabled:
            isQuotationsEnabled &&
            canAccessQuotation &&
            features.qmPendingQuotations,
        },
      ],
    },
    {
      id: Pages.Organization,
      title: translate('organizationTitle'),
      description: translate('organizationDescription'),
      route: `/${Pages.Organization}`,
      icon: 'apartment',
      enabled: isOrganizationEnabled && canAccessOrganization,
      children: [
        {
          id: SubPages.Organizations,
          title: translate('organizationOverviewTitle'),
          description: translate('organizationDescription'),
          route: `/${Pages.Organization}/overview`,
          enabled: isOrganizationEnabled && canAccessOrganization,
        },
        {
          id: SubPages.Users,
          title: translate('usersTitle'),
          description: translate('organizationDescription'),
          route: `/${Pages.Organization}/${SubPages.Users}`,
          enabled: isOrganizationEnabled && canAccessOrganization,
        },
      ],
    },
    {
      id: Pages.Role,
      title: translate('roleTitle'),
      description: translate('roleDescription'),
      route: `/${Pages.Role}`,
      icon: 'key',
      enabled: isRoleEnabled,
      children: [
        {
          id: SubPages.Matrix,
          title: translate('permissionsMatrixTitle'),
          description: translate('roleDescription'),
          route: `/${Pages.Role}/${SubPages.Matrix}`,
          enabled: isRoleEnabled,
        },
        {
          id: SubPages.Management,
          title: translate('rolesManagementTitle'),
          description: translate('roleDescription'),
          route: `/${Pages.Role}/${SubPages.Management}`,
          enabled: isRoleEnabled,
        },
      ],
    },
    {
      id: Pages.Catalog,
      title: translate('catalogTitle'),
      description: translate('catalogDescription'),
      route: `/${Pages.Catalog}`,
      icon: 'account_tree',
      enabled: isCatalogEnabled,
      children: [
        {
          id: SubPages.MasterCatalog,
          title: translate('masterCatalogMappingTitle'),
          description: translate('catalogDescription'),
          route: `/${Pages.Catalog}/${SubPages.MasterCatalog}`,
          enabled: isCatalogEnabled,
        },
        {
          id: SubPages.SalesCatalog,
          title: translate('salesCatalogTitle'),
          description: translate('catalogDescription'),
          route: `/${Pages.Catalog}/${SubPages.SalesCatalog}`,
          enabled: isCatalogEnabled,
        },
      ],
    },
    {
      id: Pages.Edith,
      title: translate('edithTitle'),
      description: translate('edithDescription'),
      route: `/${Pages.Edith}`,
      icon: 'hub',
      enabled: isEdithEnabled,
      children: [
        {
          id: SubPages.Duplicates,
          title: translate('duplicatesTitle'),
          description: translate('edithDescription'),
          route: `/${Pages.Edith}/${SubPages.Duplicates}`,
          enabled: isEdithEnabled,
        },
        {
          id: SubPages.Spelling,
          title: translate('spellingTitle'),
          description: translate('edithDescription'),
          route: `/${Pages.Edith}/${SubPages.Spelling}`,
          enabled: isEdithEnabled,
        },
      ],
    },
    {
      id: Pages.Delivery,
      title: translate('deliveryTitle'),
      description: translate('deliveryDescription'),
      route: `/${Pages.Delivery}`,
      icon: 'local_shipping',
      enabled: isDeliveryEnabled,
      children: [
        {
          id: SubPages.SuppressedDeliveryOptions,
          title: translate('deliveryOptions'),
          description: translate('deliveryOptions'),
          route: `/${Pages.Delivery}/${SubPages.SuppressedDeliveryOptions}`,
          enabled: isDeliveryEnabled && TENANT === 'kramp',
        },
        {
          id: SubPages.FreightCharges,
          title: translate('freightCharges'),
          description: translate('freightCharges'),
          route: `/${Pages.Delivery}/${SubPages.FreightCharges}`,
          enabled: isDeliveryEnabled && TENANT === 'kramp',
        },
        {
          id: SubPages.DeliveryPriority,
          title: translate('deliveryPriority'),
          description: translate('deliveryPriority'),
          route: `/${Pages.Delivery}/${SubPages.DeliveryPriority}`,
          enabled: isDeliveryEnabled && TENANT === 'kramp',
        },
        {
          id: SubPages.DeliveryTranslations,
          title: translate('deliveryTranslations'),
          description: translate('deliveryTranslations'),
          route: `/${Pages.Delivery}/${SubPages.DeliveryTranslations}`,
          enabled: isDeliveryEnabled && TENANT === 'kramp',
        },
      ],
    },
    {
      id: Pages.Search,
      title: translate('searchTitle'),
      description: translate('searchDescription'),
      route: `/${Pages.Search}`,
      icon: 'search',
      enabled: isSearchEnabled && canAccessSearch,
      children: [
        {
          id: SubPages.Synonyms,
          title: translate('synonymsTitle'),
          description: translate('searchDescription'),
          route: `/${Pages.Search}/${SubPages.Synonyms}`,
          enabled: isSearchEnabled && canAccessSearch,
        },
        {
          id: SubPages.SuppressResults,
          title: translate('suppressResultsTitle'),
          description: translate('searchDescription'),
          route: `/${Pages.Search}/${SubPages.SuppressResults}`,
          enabled: isSearchEnabled && canAccessSearch,
        },
      ],
    },
    {
      id: Pages.Alpha,
      title: translate('alphaTitle'),
      description: translate('alphaDescription'),
      route: `/${Pages.Alpha}`,
      icon: 'science',
      enabled: isAlphaEnabled,
    },
    {
      id: Pages.Hyperloop,
      title: translate('hyperloopTitle'),
      description: translate('hyperloopDescription'),
      route: `/${Pages.Hyperloop}`,
      icon: 'loop',
      enabled: isHyperloopEnabled && canAccessHyperloop,
      children: [
        {
          id: SubPages.Questions,
          title: translate('questionsTitle'),
          description: translate('hyperloopDescription'),
          route: `/${Pages.Hyperloop}/${SubPages.Questions}`,
          enabled: isHyperloopEnabled && canAccessHyperloop,
        },
        {
          id: SubPages.Export,
          title: translate('exportTitle'),
          description: translate('hyperloopDescription'),
          route: `/${Pages.Hyperloop}/${SubPages.Export}`,
          enabled: isHyperloopEnabled && canAccessHyperloop,
        },
      ],
    },
    {
      id: Pages.Experimentation,
      title: translate('experimentationTitle'),
      description: translate('experimentationDescription'),
      route: `/${Pages.Experimentation}`,
      icon: 'analytics',
      enabled: isExperimentationEnabled && canAccessExperimentation,
    },
    {
      id: Pages.Settings,
      title: translate('settingsTitle'),
      description: translate('settingsDescription'),
      route: `/${Pages.Settings}`,
      icon: 'settings',
      enabled: true,
    },
    {
      id: Pages.FeatureToggles,
      title: translate('featureTogglesTitle'),
      description: translate('featureTogglesDescription'),
      route: `/${Pages.FeatureToggles}`,
      icon: 'toggle_on',
      enabled: isFeatureTogglesEnabled,
    },
  ];

  return menuItems;
};
