import React from 'react';
import { Global } from '@emotion/react';
import { resetCSS, globalCSS } from '@dc3/utils/theme';
import { Box } from '@mui/material';
import { SideBar } from '../side-bar/SideBar';
import { TopBar } from '../top-bar/TopBar';
import { useTheme } from '@dc3/utils/theme';
import { StyledMain } from './Layout.styles';

interface LayoutProps {
  headerTitle: string;
  children?: React.ReactNode;
  /** Optional prop for show or hide the country selector by app specific */
  showCountrySelector?: boolean;
}

export const Layout = ({
  children,
  showCountrySelector = true,
  headerTitle,
}: LayoutProps) => {
  const theme = useTheme();

  return (
    <>
      <Global styles={[resetCSS, globalCSS]} />

      <Box
        display="flex"
        alignItems="stretch"
        height={`calc(100% - ${theme.dimensions.heightAppBar})`}
        marginTop={theme.dimensions.heightAppBar}
      >
        <TopBar
          headerTitle={headerTitle}
          showCountrySelector={showCountrySelector}
        />
        <SideBar />
        <StyledMain>{children}</StyledMain>
      </Box>
    </>
  );
};

export const HomeLayout = ({ children }: { children?: React.ReactNode }) => (
  <>
    <Global styles={[resetCSS, globalCSS]} />
    {children}
  </>
);

export { resetCSS, globalCSS };
