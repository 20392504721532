import styled from '@emotion/styled';
import { Text } from '@dc3/ui/text';
import MaterialMenuItem from '@mui/material/MenuItem';

export const StyledMaterialMenuItem = styled(MaterialMenuItem)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const UserMenuTitle = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
`;
