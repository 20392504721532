import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
// @todo: After migration is complete remove this and use the one from mui/material
import { Fab } from '@mui/material';
import { Box } from '@mui/material';

interface StyledSidebarProps {
  sidebarIsOpen: boolean;
}

type CombinedProps = ThemeProps & StyledSidebarProps;

export const stylesOptions = {
  // Forward all props unless it's `sidebarIsOpen` which is only used for styling.
  shouldForwardProp: (prop) => prop !== 'sidebarIsOpen',
};

const sidebarCSS = ({ theme, sidebarIsOpen }: CombinedProps) => css`
  position: relative;
  flex-shrink: 0;
  z-index: ${theme.zIndex.appBar + 10};

  width: ${sidebarIsOpen
    ? theme.dimensions.sidebarWidth
    : theme.dimensions.sidebarCollapsedWidth};
  transition: 0.5s;

  .MuiDrawer-paper {
    overflow-x: hidden;
    width: ${sidebarIsOpen
      ? theme.dimensions.sidebarWidth
      : theme.dimensions.sidebarCollapsedWidth};
    transition: 0.5s; /*  transition effect to slide in the sidenav */
  }

  .MuiListItemText-root {
    margin: 0;
  }

  .MuiListItemIcon-root {
    min-width: 32px;
    justify-content: center;
  }

  .MuiPaper-root {
    background: ${theme.appColors.sidebar};
  }

  &:hover .MuiFab-root {
    opacity: 1; /*  show collapse/expand button on hover of side bar */
  }
`;

export const StyledSideBar = styled(Box, stylesOptions)`
  ${sidebarCSS}
`;

export const CollapseDrawerButton = styled(Fab)`
  ${({ theme }) => css`
    // https://github.com/mui-org/material-ui/issues/12032
    border-radius: 50%;
    border: 1px solid ${theme.palette.grey[300]};
    position: absolute;
    top: -32px;
    right: calc(${theme.dimensions.sidebarCollapseButtonHeight} / -2);
    z-index: ${theme.zIndex.appBar + 1};
    transition: 0.3s;
    font-size: 16px;
    width: ${theme.dimensions.sidebarCollapseButtonHeight};
    height: ${theme.dimensions.sidebarCollapseButtonHeight};
    min-height: ${theme.dimensions.sidebarCollapseButtonHeight};
    background-color: ${theme.palette.background.paper};
    box-shadow: none;

    :hover {
      background-color: ${theme.palette.background.default};
    }

    span {
      font-size: 18px;
    }
  `}
`;

export const Version = styled.span`
  font-size: 11px;
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(0.5)};
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledActionsSectionCSS = ({
  theme,
  sidebarIsOpen,
}: CombinedProps) => css`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: ${theme.spacing(sidebarIsOpen ? 4 : 1)};
  transition: 0.3s;
`;

export const StyledActionsSection = styled('div', stylesOptions)`
  ${StyledActionsSectionCSS}
`;
