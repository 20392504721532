import { ThemeProps } from '@dc3/utils/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AppBar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
interface StyledTopbarProps {
  sidebarIsOpen: boolean;
}

type CombinedProps = ThemeProps & StyledTopbarProps;

export const topbarCSS = ({ theme, sidebarIsOpen }: CombinedProps) => css`
  background: ${theme.appColors.topBarHome} !important;
  color: ${theme.palette.common.white};
  backdrop-filter: blur(6px);
  width: 100%;
  /* put top bar on top of app bars used for tabbed sub nav*/
  z-index: ${theme.zIndex.appBar + 1};
  /*  transition effect to slide in the sidenav */
  transition: 0.3s;
  /* fixes hover effect on profilebutton overflowing window border + alignment with main content */
  padding-right: ${theme.spacing(1)};
  ${sidebarIsOpen && 'padding-left: 0;'};
  box-shadow: none;
`;

export const StyledTopBar = styled(AppBar, {
  // prevent DOM error unknown prop error
  shouldForwardProp: (prop) => prop !== 'sidebarIsOpen',
})`
  ${topbarCSS}
`;

const logoWrapperCSS = ({ theme, sidebarIsOpen }: CombinedProps) => css`
  width: ${sidebarIsOpen
    ? theme.dimensions.sidebarWidth
    : theme.dimensions.sidebarCollapsedWidth};
  padding-left: ${theme.spacing(2)};
  text-align: left;
  padding: 8px 16px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  height: 64px;
`;

export const LogoWrapper = styled.div`
  ${logoWrapperCSS}
`;

export const AppBarTitle = styled.div`
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const StyledIconButton = styled(IconButton)`
  &:hover {
    /* a darker background is needed to be able to see the effect on the dark primary color */
    background: rgba(0, 0, 0, 0.2);
  }
`;
